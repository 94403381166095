let _ = require('lodash');

CollectionAttachmentsController.$inject = ['CollectionAttachmentsResource', 'CurrentUser', 'CollectionMembersResource', 'attachmentFactory', 'viewMediaFactory', '$q', 'simplePopupFactory', 'notifications', '$rootScope', '$preloaded', '$log'];

function CollectionAttachmentsController(CollectionAttachmentsResource, CurrentUser, CollectionMembersResource, attachmentFactory, viewMediaFactory, $q, simplePopupFactory, notifications, $rootScope, $preloaded, $log) {
    let self = this;

    $log.debug('CollectionAttachmentsController-->load');

    function fetch_attachments() {
        $log.debug('fetch_attachments');
        $rootScope.show_dimmer();
        CollectionAttachmentsResource.get_attachments(self.collection_id)
            .then(resp => {
                $log.debug(resp);
                let attachment_partitions = _.partition(resp.data, attachment => attachment.type === 'media');
                self.media_attachments = attachment_partitions[0].map(attachment => new FolderItemProxy(attachment));
                self.other_attachments = attachment_partitions[1];
            }, err => notifications.error(err || 'Error'))
            .finally($rootScope.hide_dimmer);
    }


    class FolderItemProxy {
        constructor(attachment) {
            this.item = attachment.media_attachment;
            this.attachment = attachment;
        }

        get id() {
            return this.attachment.id;
        }

        onclick() {
            if (this.item.type === 'uploaded_document') {
                let openInNewTab = function (url) {
                    let win = window.open(url, '_blank');
                    win.focus();
                };
                openInNewTab(this.item.uploaded_document.document_url);

            } else {
                let only_media_items = self.media_attachments.filter(ma => ma.item.type !== 'uploaded_document').map(ma => ma.item);

                viewMediaFactory.show_media(only_media_items, only_media_items.indexOf(this.item));
            }
        }
    }

    self.remove_attachment = function (attacment, type) {
        /**
         * @param type 'media' or 'other'
         */
        simplePopupFactory.show_popup('Delete Attachment', 'Are you sure you want to delete this attachment?', 'Delete', 'Cancel', 'btn-red')
            .then(() => {
                $rootScope.show_dimmer();
                return CollectionAttachmentsResource.delete_attachment(self.collection_id, attacment.id);
            }, () => new Promise(()=>{}))
            .then(resp => {
                if (type === 'media') {
                    self.media_attachments.splice(self.media_attachments.indexOf(attacment), 1);
                } else {
                    self.other_attachments.splice(self.other_attachments.indexOf(attacment), 1);
                }
                notifications.success('Deleted');
            }, err => notifications.error(err || "Error"))
            .finally($rootScope.hide_dimmer);

    };

    self.attach = function () {
        attachmentFactory.add_attachments('collection_attachments', CollectionAttachmentsResource, self.collection_id)
            .then(res => {
                self.media_attachments = _.concat(self.media_attachments, res[0].map(attachment => new FolderItemProxy(attachment)));
                self.other_attachments = _.concat(self.other_attachments, res[1]);
                notifications.success('Added');
            })
    };

    self.can_join = function () {
        return true
    };

    self.send_join_request = function () {
        $rootScope.show_dimmer();
        CollectionMembersResource.join(self.collection_id)
            .then(resp => {
                let status = resp.data.status; // 'joined' or 'wait_confirmation'
                if (status === 'joined') {
                    notifications.success('Joined');
                    document.location.href = $preloaded.collection.link;
                }
                if (status === 'wait_confirmation') {
                    $rootScope.hide_dimmer();
                    self.is_join_request_pending = true;
                    simplePopupFactory.show_popup('Join request sent.', 'You will join collection after approval. We will notify you.', 'OK');
                }

            }, err => {
                notifications.error(err || 'Error');
                $rootScope.hide_dimmer();
            })
    };

    function init() {
        $log.debug('CollectionAttachmentsController-->init');
        self.collection_id = $preloaded.collection.id;
        self.collection_name = $preloaded.collection.name;
        self.is_admin = $preloaded.is_admin;
        self.is_member = $preloaded.is_member;
        self.is_shared = $preloaded.is_shared;
        self.is_guardian = $preloaded.is_guardian;
        self.is_join_request_pending = $preloaded.is_join_request_pending;
        if (self.is_shared || self.is_member || self.is_guardian) {
            fetch_attachments();
        }
    }

    init();
}

export default CollectionAttachmentsController
