<template>
    <div>
        <folder-item
                :attachment="attachment.media_attachment"
                v-for="attachment in attachments"
                :key="attachment.id"
                :size="90"
                :allow-delete="canEditAttachments"
                @delete="del(attachment)"
                @click="showAttachment(attachment.media_attachment)"
        ></folder-item>
        <div
                class="hidden-print mm-icon add-attachment"
                :style="iconStyle"
                @click='addAttachment'
                v-if="canEditAttachments"
                v-tooltip="'Add Attachment'"
        >
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';

    export default {
        name: "AttachmentsPanel",
        props: {
            canEditAttachments: {
                type: Boolean,
                default: false
            },
            targetId: {
                type: Number
            },
            attachmentsResource: {
                type: Object
            },
            size: {
                type: Number,
                default: 50,
            },
            margin: {
                type: Number,
                default: 10,
            }
        },
        data() {
            return {
                attachments: []
            };
        },
        computed: {
            iconStyle() {
                return {
                    'display': 'inline-block',
                    'vertical-align': 'middle',
                    'width': `${this.size}px`,
                    'height': `${this.size}px`,
                    'cursor': 'pointer',
                    'background-size': 'contain',
                    'margin-left': `${this.margin}px`,
                    'margin-bottom': `${this.margin}px`,
                };
            }
        },
        methods: {
            del(attachment) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const notifications = Vue.getAngularModule('notifications');
                const $q = Vue.getAngularModule('$q');
                simplePopupFactory.show_popup('Delete Attachment', 'Are you sure you want to delete this attachment?', 'Delete', 'Cancel', 'btn-red')
                    .then(() => {
                        $rootScope.show_dimmer();
                        return this.attachmentsResource.delete_attachment(this.targetId, attachment.id);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        this.attachments.splice(this.attachments.indexOf(attachment), 1);
                        notifications.success('Deleted');
                    }, err => notifications.error(err || "Error"))
                    .finally($rootScope.hide_dimmer);
            },
            showAttachment(item) {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                if (item.type === 'uploaded_document' || item.type === 'link') {
                    let openInNewTab = function (url) {
                        let win = window.open(url, '_blank');
                        win.focus();
                    };
                    if (item.type === 'uploaded_document') {
                        openInNewTab(item.uploaded_document.document_url);
                        return;
                    }
                    if (item.type === 'link') {
                        simplePopupFactory.show_popup('Open Link', `Are you sure you want to open link <br>"<i>${item.name}</i>" ?`, 'Yes', 'Cancel')
                            .then(y => {
                                openInNewTab(item.link.url);
                            });
                    }

                } else {
                    let only_media_items = this.attachments.map(a => a.media_attachment).filter(ma => ma.type !== 'uploaded_document' && ma.type !== 'link');
                    viewMediaFactory.show_media(only_media_items, only_media_items.indexOf(item));
                }
            },
            addAttachment() {
                const attachmentFactory = Vue.getAngularModule('attachmentFactory');
                const notifications = Vue.getAngularModule('notifications');
                attachmentFactory.add_attachments(undefined, this.attachmentsResource, this.targetId, true)
                    .then(res => {
                        this.attachments = _.concat(this.attachments, res[0]);
                        notifications.success('Added');
                    });
            },
            setAttachments(attachmentsList) {
                this.attachments = attachmentsList;
            }
        }
    };
</script>

<style scoped>

</style>
